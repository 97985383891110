<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link> &gt;
                    <router-link :to="{ name: 'account-search-file', params: { accountId: this.$route.params.accountId } }">Files</router-link>
                </p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 mt-2" v-if="isViewReady">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">{{ file.label }}</h1>
                <p class="text-caption text-center">File overview</p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 px-10" v-if="file">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <p class="text-overline mb-0 mt-8">Label</p>
                    <p class="mb-0 pb-0">
                        {{ file.label }}
                        <v-btn icon color="teal darken-2" @click="editFileLabel">
                            <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                        </v-btn>
                    </p>

                    <p class="text-overline mb-0 mt-8">Comment</p>
                    <p class="mb-0 pb-0">
                        {{ file.comment }}
                        <v-btn icon color="teal darken-2" @click="editFileComment">
                            <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                        </v-btn>
                    </p>

                    <p class="text-overline mb-0 mt-8">File ID</p>
                    <p class="mb-0 pb-0">
                        {{ file.id }}
                    </p>

                    <!-- TODO: the affected X list should depend on the file type... instead of hard-coding email contacts -->
                    <p class="text-overline mb-0 mt-8">Status</p>
                    <p class="mb-0 pb-0">
                        <span v-if="affectedEmailContactList.length > 0">
                            This file is subscribed by {{ affectedEmailContactList.length }} email contacts.
                            <!-- TODO: show the affectedEmailContactList -->
                        </span>
                        <span v-if="affectedEmailContactList.length === 0">
                        This file is not currently applied to any email contacts.
                        </span>
                    </p>

                    <!-- <p class="text-overline mb-0 mt-8">Security</p>
                    <p class="mb-0 pb-0">
                        <router-link :to="{ name: 'account-edit-form-access', params: { accountId: this.$route.params.accountId, fileId: this.$route.params.fileId } }">Access control</router-link>
                    </p> -->
                    <!-- <template v-if="isPermitServiceAdmin">
                    <p class="text-overline mb-0 mt-8">Service Administration <font-awesome-icon :icon="['fas', 'id-badge']" class="green--text"/></p>
                    <p class="mb-0 pb-0">
                    </p> -->
                    <v-expansion-panels class="mt-8" v-ifdev>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <span>
                                File Info <font-awesome-icon :icon="['far', 'code']" class="grey--text"/>
                                </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <pre>{{ JSON.stringify(this.form, null, 2) }}</pre>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                </v-col>
            </v-row>
            <v-dialog v-model="editFileLabelDialog" max-width="600">
            <v-card tile elevation="4" class="pa-0" max-width="600">
                <v-toolbar short flat color="white">
                    <v-toolbar-title class="green--text">Edit the file label</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="px-5">
                <v-form class="px-2">
                    <v-row>
                    <v-col>
                        <p>
                            The file label informs users about the content of communications they will receive if they subscribe to it.
                            You can change it at any time, but the new meaning should be similar to the old one. It IS shown to users.
                        </p>

                        <v-text-field
                            ref="fileLabelInput"
                            v-model="editableFileLabel"
                            label="Label"
                            :rules="newFileLabelRules"
                            validate-on-blur
                            color="teal darken-2"
                            required
                            type="text"
                            outlined
                            dense
                        >
                        </v-text-field>
                    </v-col>
                    </v-row>
                </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="4" class="green white--text" @click="saveEditFileLabel" :disabled="!isEditFileLabelComplete">
                        <span>Save</span>
                    </v-btn>
                    <v-btn text color="grey" @click="editFileLabelDialog = false">
                        <span>Cancel</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog>
            <v-dialog v-model="editFileCommentDialog" max-width="600">
            <v-card tile elevation="4" class="pa-0" max-width="600">
                <v-toolbar short flat color="white">
                    <v-toolbar-title class="green--text">Edit the file comment</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="px-5">
                <v-form class="px-2">
                    <v-row>
                    <v-col>
                        <p>
                            The file comment is a place where you can add more information for your own reference about how you use the file.
                            You can change it at any time. It is NOT shown to users.
                        </p>
                        <v-textarea v-model="editableFileComment" label="File comment"></v-textarea>
                    </v-col>
                    </v-row>
                </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="4" class="green white--text" @click="saveEditFileComment" :disabled="!isEditFileCommentComplete">
                        <span>Save</span>
                    </v-btn>
                    <v-btn text color="grey" @click="editFileCommentDialog = false">
                        <span>Cancel</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </v-col>
    </v-row>
</template>

<style>
.v-input .v-input__prepend-outer {
    margin-left: 4px !important;
    padding-left: 4px !important;
}

.v-input .v-input__append-outer {
    margin-top: 0px !important;
    padding-top: 0px !important;
}
</style>

<script>
import { mapState } from 'vuex';
// import FileList from '@/components/account-dashboard/FileList.vue';
// import { compact } from '@/sdk/input';
// import { randomText } from '@/sdk/random';

export default {
    components: {
        // FileList,
    },
    data: () => ({
        affectedEmailContactList: [],

        error: null,
        account: null,
        file: null,
        invite: null,
        // edit form title dialog
        editFileLabelDialog: false,
        editFileCommentDialog: false,
        editableFileComment: null,

        editableFileLabel: null,
    }),
    computed: {
        ...mapState({
            user: (state) => state.user,
            session: (state) => state.session,
        }),
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        accountName() {
            return this.account?.name ?? 'Unknown';
        },
        isViewReady() {
            return this.account !== null && this.form !== null;
        },
        isEditFileLabelComplete() {
            return typeof this.editableFileLabel === 'string' && this.editableFileLabel.trim().length > 0;
        },
        isEditFileCommentComplete() {
            return typeof this.editableFileComment === 'string' && this.editableFileComment.trim().length > 0;
        },
    },
    methods: {
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`loadAccount: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        async loadFile() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadFile: true });
                const response = await this.$client.account(this.$route.params.accountId).file.get(this.$route.params.fileId);
                console.log(`loadFile: response ${JSON.stringify(response)}`);
                if (response) {
                    this.file = response;
                    // TODO: set the editable fields instead, like this.editableDisplayname = response.comment ?? '';
                    // this.file.comment ??= '';
                    // this.file.reply_to ??= '';
                } else {
                    // TODO: redirect back to account list? show a not found message?
                    console.error('failed to load file');
                }
            } catch (err) {
                console.error('failed to load file', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadFile: false });
            }
        },
        async save(fileAttr) {
            try {
                this.error = false;
                this.$store.commit('loading', { saveEditFile: true });
                // TODO: only send what changed -- check input and sequence for changes separately
                const response = await this.$client.account(this.$route.params.accountId).file.edit({ id: this.$route.params.fileId }, fileAttr);
                console.log(`saveEditFile: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    return true;
                }
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit file' });
                return false;
            } catch (err) {
                console.error('failed to edit file', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit file' });
                return false;
            } finally {
                this.$store.commit('loading', { saveEditFile: false });
            }
        },
        editFileLabel() {
            this.editFileLabelDialog = true;
            this.editableFileLabel = this.file.label;
        },
        editFileComment() {
            this.editFileCommentDialog = true;
            this.editableFileComment = this.file.comment;
        },
        async saveEditFileLabel() {
            const isEdited = await this.save({ label: this.editableFileLabel });
            this.editFileLabelDialog = false;
            if (isEdited) {
                this.$set(this.file, 'label', this.editableFileLabel);
            }
        },
        async saveEditFileComment() {
            const isEdited = await this.save({ comment: this.editableFileComment });
            this.editFileCommentDialog = false;
            if (isEdited) {
                this.$set(this.file, 'comment', this.editableFileComment);
            }
        },
    },
    mounted() {
        this.loadAccount();
        this.loadFile();
    },
};
</script>
